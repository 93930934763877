<template>
  <div class="app-eggbookfx">
    <router-view />
    <e500 v-if="s500" />
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import e500 from './views/errors/500.vue';

export default {
  components: {
    e500,
  },
  data() {
    return {
      s500: false,
    };
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
  },
  created() {
    this.$store.dispatch('core/req_getNotificationImage');
    this.$store.dispatch('core/req_getSettings');
    const userRaw = this.$cookies.get('eggsbook_user');
    if (userRaw !== undefined && userRaw !== null) {
      this.$store.dispatch('user/req_getInfo');
      this.$store.dispatch('wallet/req_getListCoin');
    }
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'auth/REGISTER_SUCCESS':
        case 'auth/FORGOT_SUCCESS':
        case 'auth/LOGOUT_SUCCESS':
          this.$router.push({ name: 'Login' });
          this.outLoad();
          break;
        case 'auth/LOGIN_SUCCESS':
          this.$toastr.s('Login Success');
          this.$store.dispatch('user/req_getInfo');
          // this.$router.push({ name: 'Dashboard' });
          this.$router.push({ name: 'Member' });
          break;
        case 'user/GET_INFO_SUCCESS':
          this.outLoad();
          break;
        case 'core/SUCCESS_MESSAGE':
          this.$toastr.s(this.$store.state.core.success, 'Successfully');
          this.outLoad();
          break;
        case 'core/ERROR_MESSAGE':
          this.$toastr.e(this.$store.state.core.error, 'Oop!');
          this.outLoad();
          break;
        case 'core/ERROR_500':
          this.s500 = true;
          this.outLoad();
          break;

        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
