import Vue from 'vue';
import Vuex from 'vuex';

import moduleAuth from './module/auth';
import moduleAccount from './module/account';
import moduleDashboard from './module/dashboard';
import moduleMember from './module/member';
import moduleSupport from './module/support';
import moduleUser from './module/user';
import moduleWallet from './module/wallet';
import moduleHistory from './module/history';
import moduleCore from './config/core';

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    loadingStatus: false,
    totalSend: 0,
    totalReceive: 0,
    error: '',
    success: '',
    status: 0,
  }),
  getters: {
    LoadingStatus: (state) => state.loadingStatus,
  },
  mutations: {
    onLoad(state) {
      this.commit('StatusLoad', true);
      state.totalSend += 1;
    },
    outLoad(state) {
      state.totalReceive += 1;
      if (state.totalSend <= state.totalReceive) {
        this.commit('StatusLoad', false);
      }
    },
    StatusLoad: (state, data) => {
      if (data) {
        state.loadingStatus = true;
        return;
      }
      state.loadingStatus = false;
      state.totalSend = 0;
      state.totalReceive = 0;
    },
  },
  actions: {},
  modules: {
    auth: moduleAuth,
    account: moduleAccount,
    dashboard: moduleDashboard,
    member: moduleMember,
    support: moduleSupport,
    user: moduleUser,
    wallet: moduleWallet,
    history: moduleHistory,
    core: moduleCore,
  },
});
