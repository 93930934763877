<template>
  <div class="header">
    <div class="left-header">
      <div class="menu-icon">
        <b-button variant="outline-danger" @click.prevent="ChangeMenu()">
          <i class="far fa-bars"></i>
        </b-button>
      </div>
      <router-link :to="{ name: 'Member' }">
        <div class="logo-section">
          <div class="logo-img">
            <img src="~@/assets/images/logo/main.png" alt="" />
          </div>
        </div>
      </router-link>
    </div>
    <div class="right-header">
      <div class="notification-section" v-if="false">
        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content> &#x1f50d;<span class="sr-only">Search</span> </template>
          <b-dropdown-item href="#">Action</b-dropdown-item>
          <b-dropdown-item href="#">Another action</b-dropdown-item>
          <b-dropdown-item href="#">Something else here...</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="profile-section">
        <b-dropdown
          size="md"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          class="item-header"
        >
          <template #button-content>
            <div class="d-flex align-items-center avatar-fr">
              <div class="avatar">
                <img src="~@/assets/images/icons/user-circle.png" alt="" />
                <!-- <img
                  :src="
                    Statistical ? Statistical.image : require('@/assets/images/logo/onlyicon.png')
                  "
                  alt=""
                /> -->
              </div>
              <p class="name mb-0">{{ getNameUser }}</p>
              <div class="down">
                <img src="@/assets/images/icons/down.png" alt="" />
              </div>
            </div>
          </template>
          <b-dropdown-item split :to="{ name: 'Profile' }">
            <i class="far fa-user-cog"></i> Profile
          </b-dropdown-item>
          <b-dropdown-item split :to="{ name: 'Accounts' }" v-if="false">
            <i class="fas fa-list-alt"></i> Accounts
          </b-dropdown-item>
          <b-dropdown-item split :to="{ name: 'Support' }">
            <i class="fas fa-comment-lines"></i> Support Center
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="onLogout()">
            <i class="fad fa-power-off"></i> Logout
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  methods: {
    ChangeMenu() {
      this.$emit('show-menu', true);
    },
    onLogout() {
      this.$emit('logout', true);
    },
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
      Statistical: 'user/Statistical',
    }),
    getNameUser() {
      const user = this.UserInfo;
      if (user.name) {
        return `${user.surname} ${user.name}`;
      }
      return user.email.split('@')[0];
    },
  },
};
</script>

<style lang="scss">
.header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 3.9375rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1010;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  @media (max-width: 768px) {
    padding: 0 15px;
  }

  .left-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
      text-decoration: none;
    }
    .logo-section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .logo-img {
        @media (max-width: 575px) {
          margin-right: 8px;
        }
        img {
          height: 27px;
          width: auto;
          object-fit: scale-down;
        }
      }
      .text-logo {
        margin-left: 5px;
        span {
          &.effect {
            background: #ff5c00;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            line-height: 120%;
          }
          &.eggsbook {
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            color: #5d5d5d;
            line-height: 120%;
          }
        }
      }
    }
    .menu-icon {
      margin-right: 2.25rem;
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 575px) {
        margin-right: 1rem;
        height: 1.25rem;
        width: 1.25rem;
      }
      @media (max-width: 374px) {
        margin-right: 7px;
      }
      button {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        background: transparent;
        color: #6c757d;
        border: 0;
        svg {
          width: 1.25rem;
        }
      }
    }
  }
  .right-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 180px;
    width: 100%;
    .avatar-fr {
      justify-content: space-around;
    }
    .profile-section {
      width: 100%;
    }
    .item-header {
      width: 100%;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;
      }
    }
    .avatar-fr {
      max-width: 180px;
      width: 100%;
      background: #f0f0f0;
      padding: 8px 0;
      border-radius: 115px;
      .name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #555555;
      }
      .avatar {
        color: #6c757d;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
