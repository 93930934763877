const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({}),

  getters: {},

  actions: {
    req_postRegister({ commit }, input) {
      axios.post('api/v1/register', input).then((res) => {
        if (res) {
          commit('REGISTER_SUCCESS');
        }
      });
    },
    req_postLogin({ commit }, input) {
      axios.post('api/v1/login', input).then((res) => {
        if (res) {
          if (res.require_auth) {
            commit('REQUIRE_AUTH');
          } else {
            commit('LOGIN_SUCCESS', res);
          }
        }
      });
    },
    req_postForgot({ commit }, input) {
      axios.post('api/v1/forgot-password', input).then((res) => {
        commit('FORGOT_SUCCESS', res);
      });
    },
  },

  mutations: {
    LOGOUT_SUCCESS(state) {
      window.$cookies.remove('eggsbook_user');
    },
    LOGIN_SUCCESS: (state, data) => {
      window.$cookies.remove('eggsbook_user');
      state.userID = data.userID;
      state.token = data.token;
      axios.defaults.headers.common.Authorization = `Bearer ${data.Bearer}`;
      window.$cookies.set('eggsbook_user', { key: data.Bearer }, '2H');
    },
    REGISTER_SUCCESS() {},
    FORGOT_SUCCESS() {},
    RESEND_SUCCESS() {},
    REQUIRE_AUTH() {},
  },
};
