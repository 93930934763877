const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    user: {
      email: '',
      id: '',
    },
    kyc: {
      passport_image: '',
      passport_image_selfie: '',
      passport: '',
      status: -1,
    },
    auth: {
      enable: false,
      qr: '',
      secret: '',
    },
    statistical: {},
  }),

  getters: {
    UserInfo: (state) => state.user,
    UserKYC: (state) => state.kyc,
    UserAuth: (state) => state.auth,
    Statistical: (state) => state.statistical,
  },

  actions: {
    req_getInfo({ commit }) {
      axios.get('api/v1/auth/get-user-info').then((res) => {
        if (res) {
          commit('GET_INFO_SUCCESS', res);
        }
      });
    },
    req_postUpdateInfo({ commit }, input) {
      axios.post('api/v1/update-info', input).then((res) => {
        if (res) {
          commit('UPDATE_INFO_SUCCESS', res);
        }
      });
    },
    req_getAuth({ commit }) {
      axios.get('api/v1/profile/get-auth').then((res) => {
        if (res) {
          commit('GET_INFO_AUTH', res);
        }
      });
    },
    req_postAuth({ commit }, input) {
      axios.post('api/v1/profile/confirm-auth', input).then((res) => {
        if (res) {
          commit('UPDATE_AUTH_SUCCESS', res);
        }
      });
    },
    req_postChangePassword({ commit }, input) {
      axios.post('api/v1/profile/change-password', input).then((res) => {
        if (res) {
          commit('UPDATE_PASSWORD_SUCCESS', res);
        }
      });
    },
    req_postKYC({ commit }, input) {
      const formData = new FormData();
      formData.append('passport', input.passport);
      formData.append('passport_image', input.passport_image);
      formData.append('passport_image_selfie', input.passport_image_selfie);
      formData.append('type', input.type);

      axios
        .post('api/v1/profile/upload-kyc', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response !== null && response !== undefined) {
            commit('UPDATE_KYC_SUCCESS', response);
          }
        });
    },
    req_getKYC({ commit }) {
      axios.get('api/v1/profile/get-kyc').then((res) => {
        if (res) {
          commit('GET_INFO_KYC', res);
        }
      });
    },
  },

  mutations: {
    GET_INFO_SUCCESS(state, data) {
      state.user = {
        email: data.user.email,
        id: data.user.userId,
        surname: data.user.info_surname ?? null,
        name: data.user.info_name ?? null,
        birthday: data.user.info_birthday ?? null,
        address: data.user.info_address ?? null,
        phone: data.user.info_phone ?? null,
        zipCode: data.user.info_zipCode ?? null,
        placeBirthday: data.user.info_placeBirthday ?? null,
        level: data.user.level ?? 0,
        country: data.user.info_country ?? 0,
        url: data.base_url,
        User_Warning_IB: data.user.User_Warning_IB,
        User_Lock_IB: data.user.User_Lock_IB
      };
      state.statistical = { ...data.package, ...data.statisticalAgency };
    },
    UPDATE_INFO_SUCCESS(state, data) {
      console.log(data);
      this.dispatch('user/req_getInfo');
    },
    UPDATE_KYC_SUCCESS(state, data) {
      this.dispatch('user/req_getKYC');
      console.log(data);
    },
    UPDATE_AUTH_SUCCESS(state, data) {
      this.dispatch('user/req_getAuth');
      console.log(data);
    },
    UPDATE_PASSWORD_SUCCESS(state, data) {
      this.commit('auth/LOGOUT_SUCCESS');
    },
    GET_INFO_KYC(state, data) {
      state.kyc = data;
    },
    GET_INFO_AUTH(state, data) {
      state.auth = {
        enable: data.enable,
        qr: data.qr,
        secret: data.secret,
      };
    },
  },
};
