import { BootstrapVue } from 'bootstrap-vue';
import VueLazyload from 'vue-lazyload';
import VueToastr from 'vue-toastr';
import vueCountryRegionSelect from 'vue-country-region-select';
import VueCookies from 'vue-cookies';
import VueClipboard from 'vue-clipboard2';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/style/app.scss';

// import fontawesome
import '@/assets/font-awesome/css/duotone.min.css';
import '@/assets/font-awesome/css/fontawesome.min.css';
import '@/assets/font-awesome/css/solid.min.css';
import '@/assets/font-awesome/css/regular.min.css';
import '@/assets/font-awesome/css/brands.min.css';

import '@/assets/font-awesome/js/fontawesome';
import '@/assets/font-awesome/js/solid';
import '@/assets/font-awesome/js/duotone';
import '@/assets/font-awesome/js/regular';
import '@/assets/font-awesome/js/brands';

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHAV3,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});
Vue.use(VueCookies);
Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true;
Vue.use(BootstrapVue);

Vue.use(VueToastr, {
  defaultTimeout: 3000,
  defaultPosition: 'toast-top-right',
  defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});

Vue.use(vueCountryRegionSelect);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/icons/error.png',
  loading: '/icons/loading.svg',
  attempt: 1,
  silent: true,
  //   listenEvents: ["scroll"],
});

Vue.mixin({
  methods: {
    async genarateCaptChaV3(nameCaptCha) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha(nameCaptCha);
      return token;
    },
    TruncateToDecimals(num, mah, dec = 2) {
      // eslint-disable-next-line global-require
      const numeral = require('numeral');
      // eslint-disable-next-line no-restricted-properties
      const calcDec = Math.pow(10, dec);
      const newNum = Math.trunc(num * calcDec) / calcDec;
      const d = newNum.toString().split('.');
      return `${numeral(d[0]).format(0, 0)}${d[1] ? `.${d[1]}` : ''}`;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
});
Vue.config.productionTip = process.env.NODE_ENV === 'development';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
