const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    member: {
      list: [],
      total: 1,
      current: 1,
      perPage: 20,
    },
  }),

  getters: {
    Member: (state) => state.member,
  },

  actions: {
    req_getListMember({ commit }, input) {
      axios
        .get('api/v1/member/member-list', {
          params: input,
        })
        .then((res) => {
          if (res) {
            commit('SET_LIST_MEMBER', res);
          }
        });
    },
    req_getStatistical({ commit }, input) {
      axios
        .get('api/v1/member/statistical', {
          params: input,
        })
        .then((res) => {
          if (res) {
            commit('SET_STATISTICAL', res);
          }
        });
    },
  },

  mutations: {
    SET_LIST_MEMBER(state, member) {
      const {
        data, last_page, current_page, per_page, total,
      } = member.Member;
      state.member = {
        list: data,
        total: last_page,
        current: current_page,
        perPage: per_page,
        member: total,
      };
    },
    SET_STATISTICAL(state, statistical) {
      // state.statistical = statistical;
    },
  },
};
