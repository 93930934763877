<template>
  <div class="auth">
    <div class="top-bg">
      <div class="main-logo">
        <img src="~@/assets/images/icons/main-logo.png" alt="" />
      </div>
      <h1></h1>
    </div>
    <b-row class="mx-0 py-5">
      <b-col lg="5" xl="4" md="6" sm="7" cols="12" class="mx-auto p-0 xxl-3">
        <div class="left-auth px-3">
          <router-view></router-view>
          <Loading />
        </div>
      </b-col>
      <div class="help-container">
        <!-- <a
          href="https://www.facebook.com/esbfx"
          target="_blank"
          v-b-tooltip.hover
          title="Fanpage Facebook"
        >
          <img src="~@/assets/images/icons/facebook.png" alt="" />
        </a> -->
        <a
          href="https://t.me/ESMmarketannouncement"
          target="_blank"
          v-b-tooltip.hover
          title="Telegram Group Community"
        >
          <img src="~@/assets/images/icons/telegram.png" alt="" />
        </a>
        <a
          href="https://t.me/ESMmarketvn"
          target="_blank"
          v-b-tooltip.hover
          title="Telegram Group Community"
        >
          <img src="~@/assets/images/icons/telegram.png" alt="" />
        </a>
        <!-- <a href="https://twitter.com/ESBFX" target="_blank" v-b-tooltip.hover title="Twitter Page">
          <img src="~@/assets/images/icons/Twitter.png" alt="" />
        </a>
        <a href="https://bit.ly/30KjcL5" target="_blank" v-b-tooltip.hover title="Youtube Channel">
          <img src="~@/assets/images/icons/youtube.png" alt="" />
        </a> -->
      </div>
    </b-row>
    <b-modal
      id="notification"
      title=""
      hide-footer
      body-class="p-0"
      header-class="p-0"
      modal-class="d-flex justify-content-center flex-column"
    >
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          v-for="notifi in NotifileList"
          :key="notifi.image"
          class="img-carousel"
          :img-src="notifi.image"
        ></b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Loading from '@/components/shared/Loading.vue';

export default {
  components: { Loading },
  data() {
    return {
      slide: 0,
    };
  },
  computed: {
    ...mapGetters({
      NotifileList: 'core/NotifileList',
    }),
  },
  watch: {
    NotifileList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$bvModal.show('notification');
        }
      },
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  width: 100%;
  min-height: 100vh;
  background: url('~@/assets/images/background/login_bg.png');
  background-size: 100% 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  .top-bg {
    background: url('~@/assets/images/background/top-bg-login.png');
    background-size: 100% 100%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    min-height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-logo {
      max-width: 220px;
      width: 100%;
      img {
        width: 100%;
        height: auto;
        object-fit: scale-down;
      }
    }
  }
  .left-auth {
    padding: 1.25rem 1rem;
    position: relative;
    flex: 1;
    -webkit-transform: unset;
    transform: unset;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    display: flex;
    flex-direction: column;
    z-index: 1;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    @media (min-width: 576px) {
      max-height: 100vh;
      padding: 10px !important;
    }
  }
  .right-auth {
    background: url('~@/assets/images/background/authBg.jpg'), #f8f8f8;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    img {
      width: 100%;
      max-width: 120px;
      height: 115px;
    }
    h5 {
      color: #fff;
      margin: 40px auto 5px auto;
      font-size: clamp(15px, 1.5vw, 17px);
      font-weight: 600;
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 3px;
        width: 40%;
        margin: auto;
        background: #ff5c00;
      }
    }
    h1 {
      color: #fff;
      margin: 20px auto 5px auto;
      font-size: clamp(19px, 2.5vw, 35px);
      font-weight: 600;
    }
  }
  .help-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(-50%, -5%);
    @media (max-width: 575px) {
      position: unset;
      transform: unset;
      flex-direction: row;
      width: 100%;
      justify-content: center;
    }
    a {
      width: 40px;
      height: 40px;
      margin: 0 5px 20px;
      background: #888888;
      border-radius: 100%;
      &:hover {
        transition: all 0.4s;
        background: rgba(255, 92, 0, 1);
      }
      @media (max-width: 575px) {
        margin: 0 5px;
      }
      img {
        width: 40px;
        height: 40px;
        object-fit: scale-down;
      }
    }
  }
}
</style>
