const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    demo: [],
    live: [],
    types: [],
    liveMT5: [],
    demoMT5: [],
    listLinkDown: [],
  }),

  getters: {
    Demo: (state) => state.demo,
    Live: (state) => state.live,
    DemoMT5: (state) => state.demoMT5,
    LiveMT5: (state) => state.liveMT5,
    Types: (state) => state.types,
    ListLinkDown: (state) => state.listLinkDown,
  },

  actions: {
    req_postRegisterSub({ commit }, input) {
      axios.post('api/v1/account/register', input).then((res) => {
        if (res) {
          commit('REGISTER_SUB_SUCCESS', res);
        }
      });
    },
    req_postSettingSub({ commit }, input) {
      axios.post('api/v1/account/setting', input).then((res) => {
        if (res) {
          commit('SETTING_SUB_SUCCESS', res);
        }
      });
    },
    req_getListSub({ commit }, input) {
      axios.get('api/v1/account/list', input).then((res) => {
        if (res) {
          commit('SET_LIST_SUB', res);
        }
      });
    },
    req_getTypeSub({ commit }, input) {
      axios.get('api/v1/account/list-type', input).then((res) => {
        if (res) {
          commit('SET_TYPE_SUB', res);
        }
      });
    },
    req_postRegisterSubMT5({ commit }, input) {
      axios.post('api/v1/mt5/register', input).then((res) => {
        if (res) {
          commit('REGISTER_SUB_MT5_SUCCESS', res);
        }
      });
    },
    req_getListSubMT5({ commit }, input) {
      axios.get('api/v1/mt5/list-account', input).then((res) => {
        if (res) {
          commit('SET_LIST_SUB_MT5', res);
        }
      });
    },
    req_postSettingSubMT5({ commit }, input) {
      axios.post('api/v1/mt5/change-password', input).then((res) => {
        if (res) {
          commit('SETTING_SUB_MT5_SUCCESS', res);
        }
      });
    },
    req_postSettingLeverageMT5({ commit }, input) {
      axios.post('api/v1/mt5/update-leverage', input).then((res) => {
        if (res) {
          commit('SETTING_LEVERAGE_MT5_SUCCESS', res);
        }
      });
    },
    req_getLinkMT5({ commit }, input) {
      axios.get('api/v1/mt5/get-link', input).then((res) => {
        if (res) {
          commit('GET_LINK_DOWN_MT5', res);
        }
      });
    },
  },

  mutations: {
    REGISTER_SUB_SUCCESS() {
      this.dispatch('account/req_getListSub');
    },
    SETTING_SUB_SUCCESS() {
      this.dispatch('account/req_getListSub');
    },
    SET_LIST_SUB(state, data) {
      state.live = data.live;
      state.demo = data.demo;
    },
    SET_LIST_SUB_MT5(state, data) {
      state.liveMT5 = data.live;
      state.demoMT5 = data.demo;
    },
    SET_TYPE_SUB(state, data) {
      state.types = data;
    },
    SETTING_SUB_MT5_SUCCESS() {
      this.dispatch('account/req_getListSubMT5');
    },
    REGISTER_SUB_MT5_SUCCESS() {
      this.dispatch('account/req_getListSubMT5');
    },
    GET_LINK_DOWN_MT5(state, data) {
      state.listLinkDown = data;
    },
    SETTING_LEVERAGE_MT5_SUCCESS() {
      this.dispatch('account/req_getListSubMT5');
    }
  },
};
