const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    coin: [],
    deposit: {
      qr: null,
      address: null,
      name: null,
    },
    actions: [],
  }),

  getters: {
    Coin: (state) => state.coin,
    Deposit: (state) => state.deposit,
    Actions: (state) => state.actions,
  },

  actions: {
    req_getListCoin({ commit }) {
      axios.get('api/v1/wallet/list-coin').then((res) => {
        if (res) {
          commit('SET_LIST_COIN', res);
        }
      });
    },
    req_getListAction({ commit }) {
      axios.get('api/v1/wallet/action ').then((res) => {
        if (res) {
          commit('SET_LIST_ACTION', res);
        }
      });
    },
    req_getDepositInfo({ commit }, input) {
      axios
        .get('api/v1/wallet/address', {
          params: input,
        })
        .then((res) => {
          commit('SET_DEPOSIT_INFO', res);
        });
    },
    req_postWithdraw({ commit }, input) {
      axios.post('api/v1/wallet/withdraw', input).then((res) => {
        commit('WITHDRAW_SUCCESS', res);
      });
    },
    req_postTransfer({ commit }, input) {
      axios.post('api/v1/wallet/transfer', input).then((res) => {
        commit('TRANSFER_SUCCESS', res);
      });
    },
    req_postDepositDemo({ commit }, input) {
      axios.post('api/v1/wallet/deposit-demo', input).then((res) => {
        commit('DEPOSIT_SUCCESS', res);
      });
    },
    req_postDepositDemoMT5({ commit }, input) {
      axios.post('api/v1/mt5/deposit-demo', input).then((res) => {
        commit('DEPOSIT_MT5_SUCCESS', res);
      });
    },
    req_getDepositInfoMT5({ commit }, input) {
      axios
        .get('api/v1/mt5/address', {
          params: input,
        })
        .then((res) => {
          commit('SET_DEPOSIT_INFO_MT5', res);
        });
    },
    req_postWithdrawMT5({ commit }, input) {
      axios.post('api/v1/mt5/withdraw', input).then((res) => {
        commit('WITHDRAW_MT5_SUCCESS', res);
      });
    },
    req_postTransferMT5({ commit }, input) {
      axios.post('api/v1/mt5/transfer', input).then((res) => {
        commit('TRANSFER_MT5_SUCCESS', res);
      });
    },
  },

  mutations: {
    SET_LIST_COIN(state, data) {
      state.coin = data.coin;
      this.dispatch('wallet/req_getListAction');
    },
    SET_DEPOSIT_INFO(state, data) {
      state.deposit = {
        qr: data.Qr,
        address: data.address,
      };
    },
    SET_DEPOSIT_INFO_MT5(state, data) {
      state.deposit = {
        qr: data.Qr,
        address: data.address,
      };
    },
    WITHDRAW_SUCCESS() {
      this.dispatch('account/req_getListSub');
    },
    TRANSFER_SUCCESS() {
    },
    DEPOSIT_SUCCESS() {
      this.dispatch('account/req_getListSub');
    },
    SET_LIST_ACTION(state, actions) {
      state.actions = actions;
    },
    DEPOSIT_MT5_SUCCESS() {
      this.dispatch('account/req_getListSubMT5');
    },
    WITHDRAW_MT5_SUCCESS() {
      this.dispatch('account/req_getListSubMT5');
    },
    TRANSFER_MT5_SUCCESS() {
    },
  },
};
