<template>
  <div class="left-sidebar">
    <div class="left-sidebar-content">
      <div class="menu-item" v-if="false">
        <router-link :to="{ name: 'Dashboard' }">
          <i class="far fa-tv-alt"></i>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link :to="{ name: 'Accounts' }">
          <i class="fas fa-list-alt"></i>
          <span>Accounts</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link :to="{ name: 'Platform' }">
          <i class="fas fa-list-alt"></i>
          <span>Platform</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link :to="{ name: 'Member' }">
          <i class="far fa-users-class"></i>
          <span>Member</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link :to="{ name: 'History' }">
          <i class="far fa-files-medical"></i>
          <span>History</span>
        </router-link>
      </div>
      <div class="break-sidebar-content"></div>
      <div class="menu-item">
        <a href="https://esmmarket.com/" target="_blank" class="sub">
          <i class="fas fa-home-lg-alt"></i>
          <span>ESM MARKET HOME</span>
        </a>
      </div>
      <div class="menu-item">
        <a href="https://esmmarket.com/#form-contact" target="_blank" class="sub">
          <i class="far fa-address-book"></i>
          <span>Contact US</span>
        </a>
      </div>
      <div class="menu-item">
        <router-link :to="{ name: 'Support' }" class="sub">
          <i class="far fa-address-book"></i>
          <span>Support</span>
        </router-link>
      </div>
    </div>
    <!-- <div class="menu-support">
      <router-link :to="{ name: 'Support' }">
        <i class="fas fa-comment-lines"></i>
        <span>Support</span>
      </router-link>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.left-sidebar {
  min-width: 270px;
  max-width: 270px;
  background-color: #fff;
  // min-height: 500px;
  height: 100%;
  min-height: calc(100vh - 63px);
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  transition: left 0.2s ease-in, margin-right 0.2s ease-in;
  transition: 0.3s;
  padding: 40px 0 0 0;
  margin-top: -145px;
  border-radius: 3px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  @media (max-width: 1200px) {
    position: fixed;
    min-height: calc(100vh - 4rem);
    top: 3.75rem;
    left: 0;
    bottom: 0;
    margin-top: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 320px;
    min-height: unset;
    max-height: calc(100vh - 4rem);
    height: 100%;
  }
  @media (max-height: 700px) {
    min-height: 600px;
  }
  .left-sidebar-content {
    min-height: 400px;
    .break-sidebar-content {
      border-top: 1px solid #e1e1e1;
      width: 100%;
      opacity: 0.5;
      background: linear-gradient(#f8f8f8, transparent);
      margin-top: 1rem;
      margin-bottom: 1rem;
      height: 4px;
    }
  }

  .menu-item {
    margin: 25px 0;
    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-left: 3px solid transparent;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      text-decoration: none;
      padding: 20px;
      i,
      svg {
        width: 30px;
        display: inline-block;
        font-size: 20px;
        opacity: 0.8;
        vertical-align: middle;
        color: #a8a8b1;
        margin-right: 5px;
      }
      span {
        display: block;
        color: #404656;
        line-height: 1;
      }
      &:hover {
        span {
          color: #23222f;
        }
        font-weight: 600;
        border-left-color: #ff5c00;
        background: #25485859;
        i,
        svg {
          color: #8f8f92;
        }
      }
      &.active {
        span {
          color: #23222f;
        }
        font-weight: 600;
        border-left-color: #ff5c00;
        background: #25485859;
        i,
        svg {
          color: #8f8f92;
        }
      }
      &.sub {
        align-items: initial;
        i,
        svg {
          color: #ff5c00;
        }
        span {
          background: #ff5c00;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: clamp(14px, 1.5vw, 16px);
          text-transform: unset;
          font-weight: 700;
          line-height: 150%;
        }
      }
    }
    .help-container {
      display: flex;
      justify-content: space-around;
      padding: 10px;
      border-top: 3px solid rgba(0, 0, 0, 0.09);
      a {
        width: 30px;
        height: 30px;
        margin: 0 5px;
        padding: 0;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .menu-support {
    background: #ff5c00;
    border-radius: 10px;
    position: sticky;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
    top: calc(100% - 2rem - 40px);
    height: 50px;
    width: calc(100% - 1.2rem);
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      top: calc(100% - 40px - 7rem);
    }
    i,
    svg {
      width: 30px;
      display: inline-block;
      font-size: 20px;
      opacity: 0.8;
      vertical-align: middle;
      color: #fff;
      margin-right: 5px;
    }
    a {
      min-height: 50px;
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      span {
        color: #fff;
        font-weight: 600;
      }
    }
  }
}
</style>
