import Vue from 'vue';
import VueRouter from 'vue-router';

import publicAuthentication from '../layouts/public/AuthLayout.vue';
import privateLayout from '../layouts/private/Master.vue';
function LoadingViews(view) {
  return () => import(`@/views/${view}.vue`);
}
function LoadingViewsFolder(folder, view) {
  return () => import(`@/views/${folder}/${view}.vue`);
}
function LoadingComponents(folder, view) {
  return () => import(`@/components/${folder}/${view}.vue`);
}
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/secure',
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/secure',
    component: publicAuthentication,
    name: 'Secure',
    redirect: '/secure/login',
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoadingViewsFolder('auth', 'Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: LoadingViewsFolder('auth', 'Register'),
      },
      {
        path: 'forgot',
        name: 'Forgot',
        component: LoadingViewsFolder('auth', 'Forgot'),
      },
    ],
  },
  {
    path: '/user',
    component: privateLayout,
    redirect: '/user/member',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: LoadingViewsFolder('system', 'Dashboard'),
        meta: {
          page: `<i class="far fa-tv-alt"></i><span>Dashboard</span>`,
        },
      },
      {
        path: 'accounts',
        name: 'Accounts',
        component: LoadingViewsFolder('system', 'Accounts'),
        meta: {
          page: `<i class="fas fa-list-alt"></i><span>Accounts</span>`,
        },
      },
      {
        path: 'platform',
        name: 'Platform',
        component: LoadingViewsFolder('system', 'Platform'),
        meta: {
          page: `<i class="fas fa-list-alt"></i><span>Accounts</span>`,
        },
      },
      {
        path: 'member',
        name: 'Member',
        component: LoadingViewsFolder('system', 'Member'),
        meta: {
          page: `<i class="far fa-users-class"></i><span>Member</span>`,
        },
      },
      {
        path: 'history',
        name: 'History',
        component: LoadingViewsFolder('system', 'History'),
        meta: {
          page: `<i class="far fa-files-medical"></i><span>History</span>`,
        },
      },
      {
        path: 'support',
        name: 'Support',
        component: LoadingViewsFolder('system', 'Support'),
        meta: {
          page: ` <i class="far fa-question-square"></i><span>Support</span>`,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: LoadingViewsFolder('system', 'Profile'),
        meta: {
          page: ` <i class="far fa-user-cog"></i> <span>Profile</span>`,
        },
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: LoadingViewsFolder('errors', '404'),
  },
  {
    path: '/zxvaBQTdsffavdsadasd/dasdasdasdasd',
    name: '4012',
    component: LoadingViewsFolder('auth', 'Login2'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 1 };
    }
  },
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-children',
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach(async (to, from, next) => {
  const userRaw = window.$cookies.get('eggsbook_user');
  if (to.matched.some((m) => m.meta.requiresAuth)) {
    if (userRaw) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    if (userRaw) {
      if (to.matched[0].name == 'Secure') {
        next({ name: 'Member' });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
